<template>
  <div class="row">
    <div class="col-12 col-md-12 col-lg-12 col-xl-7">
      <h5 class="blue-text font-weight-bold">クーポン一覧</h5>
    </div>
    <div class="col-12 col-md-12 col-lg-12 col-xl-5">
      <div id="table-top-ation">
        <router-link
          :to="{
            name: 'adminRoute-coupon-create',
          }"
        >
          <button
            class="btn btn-ds blue white-text add-user"
            type="button"
            v-waves.light
          >
            <font-awesome-icon
              class="mr-2"
              :icon="{ prefix: 'fas', iconName: 'add' }"
            />
            <span>クーポンを追加</span>
          </button>
        </router-link>
      </div>
    </div>
    <div class="col-12 mt-4">
      <div v-if="couponListing">
        <div v-if="couponListing.length > 0">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">
                    <p class="m-0 ellipsis-1">クーポンID</p>
                  </th>
                  <th class="text-left">
                    <p class="m-0 ellipsis-1">クーポン名</p>
                  </th>
                  <th class="text-left">
                    <p class="m-0 ellipsis-1">利用制限</p>
                  </th>
                  <th class="text-left">
                    <p class="m-0 ellipsis-1">コード</p>
                  </th>
                  <th class="text-left">
                    <p class="m-0 ellipsis-1">利用可能回数</p>
                  </th>
                  <th class="text-left">
                    <p class="m-0 ellipsis-1">割引金額</p>
                  </th>
                  <th class="text-left">
                    <p class="m-0 ellipsis-1">有効期限(開始日)</p>
                  </th>
                  <th class="text-left">
                    <p class="m-0 ellipsis-1">有効期限(終了日)</p>
                  </th>
                  <th class="text-left">
                    <p class="m-0 ellipsis-1">操作</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(el, i) in couponListing" :key="i">
                  <td class="align-middle text-center" width="150px">
                    <p class="m-0 ellipsis-1">{{ el.id }}</p>
                  </td>
                  <td class="align-middle" width="150px">
                    <p class="m-0 ellipsis-1">
                      {{ el.name ? el.name : 'N/A' }}
                    </p>
                  </td>
                  <td class="align-middle" width="150px">
                    <p class="m-0 ellipsis-1">
                      {{ el.type ? typeText(el.type) : 'N/A' }}
                    </p>
                  </td>
                  <td class="align-middle" width="150px">
                    <p class="m-0 ellipsis-1">
                      {{ el.code ? el.code : 'N/A' }}
                    </p>
                  </td>
                  <td class="align-middle" width="150px">
                    <p class="m-0 ellipsis-1">
                      {{ el.available_count ? el.available_count : 'N/A' }}
                    </p>
                  </td>
                  <td class="align-middle" width="150px">
                    <p class="m-0 ellipsis-1">
                      {{ el.amount ? el.amount : 'N/A' }}
                    </p>
                  </td>
                  <td class="align-middle" width="150px">
                    <p class="m-0 ellipsis-1">
                      {{ el.start_at ? el.start_at : 'N/A' }}
                    </p>
                  </td>
                  <td class="align-middle" width="150px">
                    <p class="m-0 ellipsis-1">
                      {{ el.end_at ? el.end_at : 'N/A' }}
                    </p>
                  </td>
                  <td class="align-middle">
                    <div class="d-flex">
                      <router-link
                        :to="{
                          name: 'adminRoute-coupon-edit',
                          params: {
                            id: el.id,
                          },
                        }"
                      >
                      <button
                        class="btn btn-ds amber white-text shadow-1 w-50 mr-3 p-2 pr-4 pl-4"
                        type="button"
                        v-waves.light
                        style="min-width: 130px"
                      >
                        編集
                      </button>
                      </router-link>
                      <button
                        class="btn btn-ds pink white-text shadow-1 w-50 mr-3 p-2 pr-4 pl-4"
                        type="button"
                        v-waves.light
                        style="min-width: 130px"
                        @click="showDeleteConfModal(el.id)"
                      >
                        削除
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            class="mt-3 mb-0"
            :data="pagination.data"
            :limit="pagination.limit"
            :show-disabled="pagination.showDisabled"
            :size="pagination.size"
            :align="pagination.align"
            @pagination-change-page="paginateData"
          />
        </div>
        <div v-else>
          <b-card class="shadow-1 pt-5 pb-5 text-center" no-header no-footer>
            <img src="/img/icons/ico-info.svg" alt="ico-info" />
            <h3 class="m-0 mt-3 grey-text">表示できる内容がありません。</h3>
          </b-card>
        </div>
      </div>
      <div class="w-100" v-else>
        <div class="table-responsive">
          <table class="table w-100" striped hover>
            <thead>
              <tr>
                <th>クーポンID</th>
                <th>クーポン名</th>
                <th>利用制限</th>
                <th>コード</th>
                <th>利用可能回数</th>
                <th>割引金額</th>
                <th>有効期限(開始日)</th>
                <th>有効期限(終了日)</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(el, i) in 15" :key="i">
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td><b-skeleton class="mt-2"></b-skeleton></td>
                <td>
                  <div class="d-flex">
                    <b-skeleton class="w-50 mr-3" type="button"></b-skeleton>
                    <b-skeleton class="w-50 mr-3" type="button"></b-skeleton>
                    <b-skeleton class="w-50" type="button"></b-skeleton>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <b-modal
      id="coupon-delete-conf-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          クーポンを削除しますか
        </h5>
        <div class="row mb-3 mt-5">
          <div class="col-12">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="deleteCoupon()"
            >
              はい
            </button>
            <button
              class="btn btn-ds grey white-text pt-2 pb-2 mt-5 col-md-5 col-lg-5"
              type="button"
              v-waves.light
              v-on:click="$bvModal.hide('coupon-delete-conf-modal')"
            >
              いいえ
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="coupon-delete-success-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          クーポンが削除されました
        </h5>
        <div class="row mb-3 mt-5">
          <div class="col-12">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="$bvModal.hide('coupon-delete-success-modal')"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// Import Services
import { adm_stagingService_GetCoupon } from '../../services/admin/staging';
import { adm_stagingService_DeleteCoupon } from '../../services/admin/staging';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Dashboard',
    };
  },

  data() {
    return {
      search: '',
      couponListing: null,
      pagination: {
        data: null,
        limit: 3,
        showDisabled: false,
        size: 'default',
        align: 'center',
      },
      debounce: null,
      deleteId: null,
    };
  },
  watch: {
    $route() {
      this.getResults(this.getUrlQueryParamsPage());
    },
  },
  mounted() {
    this.getResults(this.getUrlQueryParamsPage());
  },
  methods: {
    showDeleteConfModal(id) {
      this.deleteId = id;
      this.$bvModal.show('coupon-delete-conf-modal');
    },
    async deleteCoupon() {
      let accessToken = localStorage.getItem('_accessToken');
      let id = this.deleteId;
      this.$store.state.modalLoaderMessage = '削除中';
      this.$bvModal.show('modal-loader');
      await adm_stagingService_DeleteCoupon({
        id: id,
        token: accessToken,
      })
        .then((response) => {
          console.log(response);
          this.getResults(this.getUrlQueryParamsPage());
          setTimeout(() => {
            this.$bvModal.hide('coupon-delete-conf-modal');
            this.$bvModal.hide('modal-loader');
            this.$bvModal.show('coupon-delete-success-modal');
          }, 500);
        })
        .catch((error) => {
          console.log(error);

          this.$bvToast.toast(
            'クーポンの削除中にエラーが発生しました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );

          setTimeout(() => {
            this.$bvModal.hide('modal-loader');
          }, 500);
        });
    },
    getUrlQueryParamsPage() {
      let currentRouteURLPrams = new URLSearchParams(location.search);
      return currentRouteURLPrams.get('page');
    },
    getResults(page) {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');

      _this.couponListing = null;

      adm_stagingService_GetCoupon({
        page: page ? page : 1,
        token: accessToken,
        search: _this.search ? _this.search : '',
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          let filteredData = data.map((el) => {
            return {
              id: el.id,
              name: el.name ? el.name : null,
              type: el.type ? el.type : null,
              code: el.code ? el.code : null,
              available_count: el.available_count ? el.available_count : null,
              amount: el.amount ? el.amount : null,
              start_at: el.start_at ? el.start_at : null,
              end_at: el.end_at ? el.end_at : null,
            };
          });
          console.log('filteredData')
          console.log(filteredData);

          _this.couponListing = filteredData;
          _this.pagination.data = data;
        })
        .catch((error) => {
          console.log(error);
        });

      _this.$store.state.currentPaginationPage = page;
    },
    paginateData(page) {
      let _this = this;
      let url_page = _this.getUrlQueryParamsPage();
      if (url_page != page) {
        _this.$router.push({
          name: 'adminRoute-coupon-list',
          query: { page: page },
        });
      }
    },
    typeText(type) {
      let typeName = '';
      switch (type) {
        case 1:
          typeName = '1度のみ';
          break;
        case 2:
          typeName = '複数回';
          break;
        case 3:
          typeName = '何度でも';
          break;
      }

      return typeName;
    }
  },
};
</script>

<style scoped>
/* Init Style */
.table thead {
  background: #fff;
}

.table thead th {
  border-bottom: 0;
  color: #000;
  font-weight: bold !important;
  text-align: center;
  border-top: 0;
  font-weight: 400;
}

.table tbody td {
  border: none;
  cursor: pointer;
}

.table tbody tr {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.table tbody tr:hover {
  background: #d1d1d1;
}

/* Custom Style */
#table-top-ation {
  display: flex;
}

@media only screen and (max-width: 767px) {
  #table-top-ation {
    display: block;
  }
}

#table-top-ation .search {
  display: flex;
  margin-right: 20px;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid #d1d1d1;
}

@media only screen and (max-width: 767px) {
  #table-top-ation .search {
    margin-bottom: 14px;
  }
}

#table-top-ation .search .icon {
  font-size: 24px;
  padding: 10px;
  text-align: center;
  background: #fff;
  color: #d1d1d1;
}

#table-top-ation .search input {
  width: 100%;
  border: none;
  padding: 10px;
}

#table-top-ation .search input:focus {
  outline: none;
}

#table-top-ation .add-user {
  width: 200px;
}

@media only screen and (max-width: 767px) {
  #table-top-ation .add-user {
    width: 100%;
  }
}
</style>